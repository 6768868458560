.privacy {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 16px;
}

.privacy h3 {
    margin-bottom: 30px;
}

.privacy div {
    max-width: 1000px;
    margin: 120px auto 20px;
    padding: 30px 30px;

    overflow: hidden;
    border-radius: 12px;
    background-color: white;
}

.privacy p {
    max-height: 480px;
    font-style: italic;
    overflow-y: auto;
    width:100%;
    float:left;
}


.privacy div p {
    width:100%;
    float:left;
    margin-bottom:10px;
}
.privacy  h4{
    text-align:center;
}