.welcome_placeholder {
  padding: 10px;
  line-height: 1.5;
  font-weight: 700;
  color: white;
}

.inf_normal {
  border-radius: 50% !important;
  background-color: #fff !important;
}

.infoItem {
  color: white;
  background-color: #000;
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}

.espaLogo {
  width: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 4px;
}

.WelcomeContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.espaText {
  font-size: 12px;
  color: #2a7da7;
  font-weight: 600;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .welcome_placeholder {
    text-align: center;
  }

  .hide {
    display: none !important;
  }
}
